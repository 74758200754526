import { useState, useEffect } from 'preact/hooks'
import { orderedGallerySlots } from '../components/gallery'
import { dataDir } from '../config'

const cache = {}
const handlers = {}
const errors = {}
const usage = {}
const lastAccess = {}

class FetchError extends Error {}

export function resetErrors() {
  Object.keys(errors).forEach(key => { delete errors[keys] })
}

function triggerHandlers(path, val) {
  handlers[path].forEach(fn => fn(val))
  delete handlers[path]
}

export function cached(url) {
  return window.cacheid ? `${url}?cacheid=${window.cacheid}` : url
}

async function runQuery(path) {
  try {
    const res = await fetch(cached(path))
    if (res.ok) {
      const json = await res.json()
      if (json?.type === 'gallery') {
        json.contents = orderedGallerySlots(json)
      }
      cache[path] = {...json, loaded: true}
      triggerHandlers(path, s => ({...s, success: json}))
    } else {
      throw new FetchError(`${path}: ${res.status}`)
    }
  } catch(e) {
    errors[path] = e 
    triggerHandlers(path, s => ({...s, error: e}))
  }
}

const expire = 3 * 60 * 1000
function cleanUp() {
  const now = Date.now()
  Object.keys(lastAccess).forEach(path => {
    if (lastAccess[path] + expire < now) {
      delete lastAccess[path]
      delete cache[path]
    }
  })
}

function addUsage(path) {
  if (usage[path] > 0) {
    usage[path] = usage[path] + 1
  } else {
    delete lastAccess[path]
    usage[path] = 1
  }
}

function removeUsage(path) {
  usage[path] = usage[path] - 1
  if (usage[path] == 0) {
    lastAccess[path] = Date.now()
    setTimeout(cleanUp, expire)
  }
}

function normalizePath(path) {
  return path && `${dataDir}${path}.json`
}

export function queryCache(path) {
  path = normalizePath(path)
  return path && cache[path]
}

function pushHandler(path, handler) {
  if (handlers[path]) {
    if (handlers[path].find(i => i === handler) === -1) {
      handlers[path].push(handler)
    }
  } else {
    handlers[path] = [ handler ]
    runQuery(path)
  }
}

export async function executeOneQueryOf(pathes, options={}) {
  const value = pathes.map(path => queryCache(path)).find(val => !!val)
  if (value) return value
  else return executeQuery(pathes[0])
}

export async function executeQuery(path, options={}) {
  path = normalizePath(path)
  if (cache[path]) return cache[path]
  if (errors[path]) throw errors[path]
  const promise = new Promise((resolve, reject) => {
    const handler = () => {
      if (cache[path]) resolve(cache[path])
      if (errors[path]) reject(errors[path])
    }
    pushHandler(path, handler)
  })
  promise.cache = path && cache[path]
  if (options.fallbacks) {
    promise.fallback = options.fallbacks.map(path => queryCache(path)).find(val => !!val)
  } 
  return promise
}

export function useQuery(path, options={}) {
  const [status, setStatus] = useState({})
  const enabled = path && (Object.hasOwnProperty('enabled') ? !!options.enabled : true)
  path = path && normalizePath(path)
  useEffect(() => {
    if (enabled) {
      addUsage(path)
      return () => { removeUsage(path) }
    }
  }, [path, enabled])
  if (enabled) {
    if (cache[path]) return cache[path]
    if (errors[path]) throw errors[path]
    pushHandler(path, setStatus)
  }
  if (path && options.keepPreviousData && status.success) return status.success
  if (path && cache[path]) return cache[path]
  if (options.fallbacks) {
    const fallback = options.fallbacks.map(path => queryCache(path)).find(val => !!val)
    if (fallback) return fallback
  } 
  return undefined
}

export function useQueries(pathes, options={}) {
  const [status, setStatus] = useState([])
  pathes = (pathes || []).map(normalizePath)
  const enabled = (pathes.length > 0) && (Object.hasOwnProperty('enabled') ? !!options.enabled : true)
  useEffect(() => {
    if (enabled) {
      pathes.map(addUsage)
      return () => { pathes.map(removeUsage) }
    }
  }, [pathes, enabled])
  if (enabled) {
    pathes.map( (path, index) => {
      if (cache[path]) return cache[path]
      if (errors[path]) throw errors[path]
      pushHandler(path, 
        (res) => setStatus((old) => {
          const fresh = [...old]
          fresh[index] = res
          return fresh
        })
      )
    })
  }
  function getData(path) {
    if (path && options.keepPreviousData && status.success) return status.success
    if (path && cache[path]) return cache[path]
    if (options.fallbacks) {
      const fallback = options.fallbacks.map(path => queryCache(path)).find(val => !!val)
      if (fallback) return fallback
    } 
    return undefined
  }
  return pathes.map(getData)
}