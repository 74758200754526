import { useEffect } from 'preact/hooks'

export function useKeydown(fn, {key, modifiers={}, target, disabled, everywhere}={}) {
  useEffect(() => {
    if (!disabled || !fn) {
      const onKeypress = (e) => {
        if (!everywhere && ['INPUT', 'TEXTAREA', 'OPTION', 'SELECT'].includes(e.target.nodeName)) return
        if (key) {
          if (e.key != key && e.code != key) return
          if (!!e.altKey != !!modifiers.altKey) return
          if (!!e.ctrlKey != !!modifiers.ctrlKey) return
          if (!!e.isCompositing != !!modifiers.isCompositing) return
          if (!!e.metaKey != !!modifiers.metaKey) return
          if (!!e.shiftKey != !!modifiers.shiftKey) return
        }
        fn(e)
      }
      (target?.current || target || window).addEventListener("keydown", onKeypress, false)
      return () => { (target || window).removeEventListener("keydown", onKeypress, false) }
    }
  }, [key, key, modifiers, target, disabled])
}
