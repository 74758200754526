import { useWindowSize } from "../utils/window_size"
import { useImageContent } from "../utils/content"
import { replaceLocation, useLocation, setLocation } from "../utils/location"
import { useTitle } from "../utils/title"
import { ImageHeader } from "../components/header"
import { MainImage } from "../components/image"
import useContent from '../utils/content'
import { useMemo, useEffect, useRef, useCallback } from 'preact/hooks'
import { Footer } from '../components/footer'
import { Main } from "./content"
import { styled } from "goober"
import { useOnSwipe } from "../utils/swipe"
import { useKeydown } from "../utils/keyboard"

const ActionList = styled('div')`
display: flex;
height: 3rem;
line-height: 3rem;
vertical-align: middle;
padding: 0 0.5rem;
justify-content: center;
`

const Button = styled('button')`
border: none;
background-color: transparent;
width: 3rem;
text-align: center;
font-size: 1.5rem;
cursor: pointer;
margin: 0.25rem 0.5rem;
`

function getIdentity() {
  const identity = window.localStorage.getItem('identity')
  console.log('identity', identity)
  if (identity) return identity
  const uuid = self.crypto.randomUUID()
  console.log('uuid', uuid)
  window.localStorage.setItem('identity', uuid)
  return uuid
}

function getSession() {
  const identity = window.sessionStorage.getItem('identity')
  if (identity) return identity
  const uuid = self.crypto.randomUUID()
  window.sessionStorage.setItem('identity', uuid)
  return uuid
}

async function sendVote(url, note) {
  const data = {
    identity: getIdentity(),
    session: getSession(),
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    url: url,
    note: note,
    localDate: (new Date()).toISOString(), 
    cacheid: window.cacheid,
  }
  const xhr = new XMLHttpRequest()
  xhr.open("POST", "/api.vote.php", true)
  xhr.setRequestHeader("Content-Type", "application/json")
  const json = JSON.stringify(data)
  xhr.send(json)
  console.log("sent", data)
}

function vote(e, vote, {url, next}) {
  e && e.preventDefault && e.preventDefault();
  sendVote(url, vote)
  next()
}

function Actions({url, next}) {
    const reject = useCallback((e) => vote(e, 'reject', { url, next }), [url, next])
    const down = useCallback((e) => vote(e, 'thumbdown', { url, next }), [url, next])
    const pass = useCallback((e) => vote(e, 'pass', { url, next }), [url, next])
    const up = useCallback((e) => vote(e, 'thumbup', { url, next }), [url, next])
    const love = useCallback((e) => vote(e, 'love', { url, next }), [url, next])
    useKeydown(reject, { key: "ArrowLeft" })
    useKeydown(down, { key: "ArrowDown" })
    useKeydown(pass, { key: "Space" })
    useKeydown(pass, { key: " " })
    useKeydown(up, { key: "ArrowUp" })
    useKeydown(love, { key: "ArrowRight" })
    useOnSwipe({ onRight: up, onLeft: down })
    return <ActionList>
        <Button type="button" title="je n'aime vraiment pas" onClick={reject}>❌</Button>
        <Button type="button" title="je n'aime pas" onClick={down}>👎</Button>
        <Button type="button" title="voir une autre image" onClick={pass}>🎲</Button>
        <Button type="button" title="j'aime" onClick={up}>👍</Button>
        <Button type="button" title="j'aime énormément" onClick={love}>❤️</Button>
    </ActionList>
}

function random({images, used}) {
  if (used.length == images.length) while(used.length > 0) used.pop();
  while(true) {
    const random = (images.length > 0) ? Math.floor(Math.random() * images.length) : 0;
    const image = images[random]
    if (!used.includes(image)) {
      used.push(image)
      return image
    }
  }
}

export default function VotePage({projects, image}) {
    const used = useRef([])
    const size = useWindowSize()
    const images = useImageContent(projects)
    const location = useLocation({images})
    const url = image || random({images, used: used.current})  
    if (!image && url) {
        replaceLocation({ url: `${location.pathname.replace(/(\/x\/vote\/[^/]+)(\/.*)?/, `$1/${url.substring(3)}`)}` })
    }
    const { content } = useContent(url)
    const colors = useRef({})
    const pageTitle = (content?.title && content.url !== '/') ? `${content.title} - ` : ''
    const next = useCallback(() => { 
      const url = random({images, used: used.current})  
      setLocation({ url:location.pathname.replace(/\/[^/]+$/, url.substring(2))})
    }, [url, images])
    useTitle(pageTitle + 'racorps.fr')
    if (content?.colors) colors.current = content.colors
    return <div>
    <ImageHeader color={colors.current.text} background={colors.current.background} current={content}  /> 
    <Main>
      <MainImage key={content?.url} longdesc={content?.longdesc} context={content?.url} width={content?.image?.width} height={content?.image?.height} title={content?.title} colors={colors.current}>
       {url && <Actions url={url} next={next} />}
      </MainImage>
    </Main>
    <Footer />
  </div>
}

